.text-pink {
    color: #DC1A83;
}

.gm-style .gm-style-iw {
    width: 100%;
    max-height: 470px !important;
    display: block;

    @media screen and (max-width: 500px) {
        min-width: 95vw !important;
    }
}

.gm-style-iw-d {
    max-height: 470px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#plots {
    .large {
      span {
        display: inline-block;
      }
      .left {
          display: inline-block;
        span {
          &.pink {
            color: #DC1A83;
            padding-right: 25px;
            @media screen and (max-width: 300px) {
                padding-right: 10px;
            }
          }
        }
      }

      .right {
          display: inline-block;
        span {
            padding-left: 30px;
            @media screen and (max-width: 300px) {
                padding-left: 10px;
            }
        }
      }
    }
  }  